<template>
    <div style="width: 1000px; margin-top: 20px;">
      <moduleForm :dataContainer="dataContainer" :edit-mode="false" :formHeader="true" :formTitle="'Add ' + module.info.caption"></moduleForm>
    </div>
</template>

<script>
    import dataContainer from 'devegram-vue-data-collecter-eui/src/mixins/dataContainer';
    import module from './../index.js'

    export default {
        mixins: [dataContainer],
        components: {
            moduleForm: () => import('./../form/tax-report/View'),

        },
        data() {
            return {
                dataContainer: this,
                module: module
            }
        }
    }
</script>
